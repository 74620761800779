import { requiresBasicAuth } from "@octokit/auth-oauth-user";
import { getAppAuthentication } from "./get-app-authentication";
import { getInstallationAuthentication } from "./get-installation-authentication";
import { requiresAppAuth } from "./requires-app-auth";
const FIVE_SECONDS_IN_MS = 5 * 1000;
function isNotTimeSkewError(error) {
    return !(error.message.match(/'Expiration time' claim \('exp'\) must be a numeric value representing the future time at which the assertion expires/) ||
        error.message.match(/'Issued at' claim \('iat'\) must be an Integer representing the time that the assertion was issued/));
}
export async function hook(state, request, route, parameters) {
    const endpoint = request.endpoint.merge(route, parameters);
    const url = endpoint.url;
    // Do not intercept request to retrieve a new token
    if (/\/login\/oauth\/access_token$/.test(url)) {
        return request(endpoint);
    }
    if (requiresAppAuth(url.replace(request.endpoint.DEFAULTS.baseUrl, ""))) {
        const { token } = await getAppAuthentication(state);
        endpoint.headers.authorization = `bearer ${token}`;
        let response;
        try {
            response = await request(endpoint);
        }
        catch (error) {
            // If there's an issue with the expiration, regenerate the token and try again.
            // Otherwise rethrow the error for upstream handling.
            if (isNotTimeSkewError(error)) {
                throw error;
            }
            // If the date header is missing, we can't correct the system time skew.
            // Throw the error to be handled upstream.
            if (typeof error.response.headers.date === "undefined") {
                throw error;
            }
            const diff = Math.floor((Date.parse(error.response.headers.date) -
                Date.parse(new Date().toString())) /
                1000);
            state.log.warn(error.message);
            state.log.warn(`[@octokit/auth-app] GitHub API time and system time are different by ${diff} seconds. Retrying request with the difference accounted for.`);
            const { token } = await getAppAuthentication({
                ...state,
                timeDifference: diff,
            });
            endpoint.headers.authorization = `bearer ${token}`;
            return request(endpoint);
        }
        return response;
    }
    if (requiresBasicAuth(url)) {
        const authentication = await state.oauthApp({ type: "oauth-app" });
        endpoint.headers.authorization = authentication.headers.authorization;
        return request(endpoint);
    }
    const { token, createdAt } = await getInstallationAuthentication(state, 
    // @ts-expect-error TBD
    {}, request);
    endpoint.headers.authorization = `token ${token}`;
    return sendRequestWithRetries(state, request, endpoint, createdAt);
}
/**
 * Newly created tokens might not be accessible immediately after creation.
 * In case of a 401 response, we retry with an exponential delay until more
 * than five seconds pass since the creation of the token.
 *
 * @see https://github.com/octokit/auth-app.js/issues/65
 */
async function sendRequestWithRetries(state, request, options, createdAt, retries = 0) {
    const timeSinceTokenCreationInMs = +new Date() - +new Date(createdAt);
    try {
        return await request(options);
    }
    catch (error) {
        if (error.status !== 401) {
            throw error;
        }
        if (timeSinceTokenCreationInMs >= FIVE_SECONDS_IN_MS) {
            if (retries > 0) {
                error.message = `After ${retries} retries within ${timeSinceTokenCreationInMs / 1000}s of creating the installation access token, the response remains 401. At this point, the cause may be an authentication problem or a system outage. Please check https://www.githubstatus.com for status information`;
            }
            throw error;
        }
        ++retries;
        const awaitTime = retries * 1000;
        state.log.warn(`[@octokit/auth-app] Retrying after 401 response to account for token replication delay (retry: ${retries}, wait: ${awaitTime / 1000}s)`);
        await new Promise((resolve) => setTimeout(resolve, awaitTime));
        return sendRequestWithRetries(state, request, options, createdAt, retries);
    }
}
