import * as OAuthMethods from "@octokit/oauth-methods";
import { emitEvent } from "../emit-event";
import { createOAuthUserAuth } from "@octokit/auth-oauth-user";
export async function refreshTokenWithState(state, options) {
    if (state.clientType === "oauth-app") {
        throw new Error("[@octokit/oauth-app] app.refreshToken() is not supported for OAuth Apps");
    }
    const response = await OAuthMethods.refreshToken({
        clientType: "github-app",
        clientId: state.clientId,
        clientSecret: state.clientSecret,
        request: state.octokit.request,
        refreshToken: options.refreshToken,
    });
    const authentication = Object.assign(response.authentication, {
        type: "token",
        tokenType: "oauth",
    });
    await emitEvent(state, {
        name: "token",
        action: "refreshed",
        token: response.authentication.token,
        authentication: authentication,
        octokit: new state.Octokit({
            authStrategy: createOAuthUserAuth,
            auth: {
                clientType: state.clientType,
                clientId: state.clientId,
                clientSecret: state.clientSecret,
                token: response.authentication.token,
            },
        }),
    });
    return { ...response, authentication };
}
