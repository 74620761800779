import { request as defaultRequest } from "@octokit/request";
import btoa from "btoa-lite";
export async function resetToken(options) {
    const request = options.request ||
        /* istanbul ignore next: we always pass a custom request in tests */
        defaultRequest;
    const auth = btoa(`${options.clientId}:${options.clientSecret}`);
    const response = await request("PATCH /applications/{client_id}/token", {
        headers: {
            authorization: `basic ${auth}`,
        },
        client_id: options.clientId,
        access_token: options.token,
    });
    const authentication = {
        clientType: options.clientType,
        clientId: options.clientId,
        clientSecret: options.clientSecret,
        token: response.data.token,
        scopes: response.data.scopes,
    };
    if (response.data.expires_at)
        authentication.expiresAt = response.data.expires_at;
    if (options.clientType === "github-app") {
        delete authentication.scopes;
    }
    return { ...response, authentication };
}
