import { emitterEventNames } from "../generated/webhook-names";
function handleEventHandlers(state, webhookName, handler) {
    if (!state.hooks[webhookName]) {
        state.hooks[webhookName] = [];
    }
    state.hooks[webhookName].push(handler);
}
export function receiverOn(state, webhookNameOrNames, handler) {
    if (Array.isArray(webhookNameOrNames)) {
        webhookNameOrNames.forEach((webhookName) => receiverOn(state, webhookName, handler));
        return;
    }
    if (["*", "error"].includes(webhookNameOrNames)) {
        const webhookName = webhookNameOrNames === "*" ? "any" : webhookNameOrNames;
        const message = `Using the "${webhookNameOrNames}" event with the regular Webhooks.on() function is not supported. Please use the Webhooks.on${webhookName.charAt(0).toUpperCase() + webhookName.slice(1)}() method instead`;
        throw new Error(message);
    }
    if (!emitterEventNames.includes(webhookNameOrNames)) {
        state.log.warn(`"${webhookNameOrNames}" is not a known webhook name (https://developer.github.com/v3/activity/events/types/)`);
    }
    handleEventHandlers(state, webhookNameOrNames, handler);
}
export function receiverOnAny(state, handler) {
    handleEventHandlers(state, "*", handler);
}
export function receiverOnError(state, handler) {
    handleEventHandlers(state, "error", handler);
}
