import { request as defaultRequest } from "@octokit/request";
import { oauthRequest } from "./utils";
export async function exchangeWebFlowCode(options) {
    const request = options.request ||
        /* istanbul ignore next: we always pass a custom request in tests */
        defaultRequest;
    const response = await oauthRequest(request, "POST /login/oauth/access_token", {
        client_id: options.clientId,
        client_secret: options.clientSecret,
        code: options.code,
        redirect_uri: options.redirectUrl,
    });
    const authentication = {
        clientType: options.clientType,
        clientId: options.clientId,
        clientSecret: options.clientSecret,
        token: response.data.access_token,
        scopes: response.data.scope.split(/\s+/).filter(Boolean),
    };
    if (options.clientType === "github-app") {
        if ("refresh_token" in response.data) {
            const apiTimeInMs = new Date(response.headers.date).getTime();
            (authentication.refreshToken = response.data.refresh_token),
                (authentication.expiresAt = toTimestamp(apiTimeInMs, response.data.expires_in)),
                (authentication.refreshTokenExpiresAt = toTimestamp(apiTimeInMs, response.data.refresh_token_expires_in));
        }
        delete authentication.scopes;
    }
    return { ...response, authentication };
}
function toTimestamp(apiTimeInMs, expirationInSeconds) {
    return new Date(apiTimeInMs + expirationInSeconds * 1000).toISOString();
}
